<template>

    <div id="app">
        <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
        <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
            <v-layout column>
                <div>
                    <strong>{{ snackbartest.title }}</strong>
                </div>
                <div>{{ snackbartest.text }}</div>
            </v-layout>
        </v-layout>
        <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
            <v-icon>clear</v-icon>
        </v-btn>
    </v-snackbar>
        <v-data-table
    :headers="headers"
    :items="list"
    sort-by="code"
    class="elevation-1"
  >
     <template v-slot:top>
      <v-toolbar
        flat
      >
              <v-toolbar-title>LISTE DES TEXTES DEFILANTS</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialog"
          max-width="500px">
        
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      color="primary"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
        </template>
        </v-snackbar>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
             @click="nouveau()" 
            >
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                     

                 <v-text-field
                      v-model="categorie"
                      label="CATEGORIE *"
                      dense
                      outlined>
                </v-text-field>
                     
                <v-text-field
                      v-model="texte"
                      label="TEXTE "
                      dense
                      outlined>
                </v-text-field>
                
                <v-text-field
                      v-model="lien1"
                      label="LIEN 1 "
                      dense
                      outlined>
                </v-text-field>
                        
                <v-text-field
                      v-model="lien2"
                      label="LIEN 2 "
                      dense
                      outlined>
                </v-text-field>
                
                <v-text-field
                      v-model="date_limite"
                      label="DATE LIMITE "
                      dense
                      outlined>
                </v-text-field>
                
                   
                </v-form>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              
              <v-btn
                color="blue darken-1"
                text
                @click="dialog=false">
                Fermer
              </v-btn>
              
              <v-btn
                color="blue darken-1"
                text
                @click="save">
                Enregistrer
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
              <v-btn color="blue darken-1" text @click="supdata()">Confirmer</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>

    </template>

    <template v-slot:[`item.odd`]="{ item }">
       {{ item.code }} - {{ item.libelle }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="Actulisation"
      >
        Actualiser la liste
      </v-btn>
    </template>


  </v-data-table>
        

</div>

</template>

<script>
import api from './../../../serviceApi/apiService'

export default {
  data:() =>({
        multiLine: true,
        snackbartest: false,
        textmessage: `message`,
        
      snackbar: false,
      snackbars:false,
      text: `message`,
      textsnackbar:'messages',
      dialog: false,
      dialogDelete: false,
      textetat:false,
          headers: [
        { text: 'ID', value: 'id' ,divider: true,width: '30px'},
        { text: 'CATEGORIE', value: 'categorie' ,divider: true,width: '150px'},
         { text: 'TEXTE', value: 'texte' ,divider: true,width: '450px'},
        { text: 'LIEN 1', value: 'lien1' ,divider: true,width: '200px'},
        { text: 'LIEN 2', value: 'lien2' ,divider: true,width: '200px'},
        { text: 'DATE LIMITE', value: 'date_limite' ,divider: true,width: '150px'},
        { text: 'ACTIONS', value: 'actions' ,divider: true,width: '100px'},
      ],
         list:[],
         categorie:'',
         id:'0',
         texte:'',
         lien1:'',
         lien2:'',
         date_limite: '',
         textetat:false,
        formTitle:"Formulaire d'enregistrement",

  }),
     methods: {
       
      readAll: async function() {
        const data = await api.readAll('defilants/all');
        this.list = data.list;
      },
      
   createUpdatedata: async function() {
      
      let fd=new FormData();
      fd.append('id',this.id);
      fd.append('categorie',this.categorie);
      fd.append('texte',this.texte);
      fd.append('lien1',this.lien1);
      fd.append('lien2',this.lien2);
      fd.append('date_limite',this.date_limite);
      
   if(this.textetat){
      const res=await api.createUpdatedata('defilants/update/'+this.id,fd);
        
        if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;
        } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
        }

    }else{
     const res=await api.createUpdatedata('defilants/add',fd);    
        if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                 
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                }
      }
        
        this.clear();       
        this.readAll();
      
    },
     
     delete: async function() {
       let fd=new FormData();
       fd.append('id',this.id);
       const res = await api.createUpdatedata('defilants/delete/'+this.id,fd);   
      
    this.readAll();
      this.dialogDelete=false;
    },
    
    supdata(){
      this.delete();     
    },

    save(){
      if(this.categorie ==''|| this.texte==''||  this.date_limite==''){
         this.textmessage = "Veuillez remplir les champs obligatoires";
                this.snackbartest = {
                    color: "red",
                    icon: "mdi-alert",
                    mode: "multi-line",
                    position: "top",
                    timeout: 2500,
                    title: "Erreur",
                    text: this.textmessage,
                    visible: true
                };
        return;
      }
    
      this.createUpdatedata();
      
    },
    
    nouveau(){
        this.clear();
        this.textetat = false;
        this.dialog=true;
    },

    editItem(item){
        //alert(item.id);
        this.id=item.id;
        this.categorie=item.categorie;
        this.texte=item.texte;
        this.lien1=item.lien1;
        this.lien2=item.lien2;
        this.date_limite=item.date_limite;      
        this.textetat = true;    
        this.dialog=true;
    },

    deleteItem(item){
       this.id=item.id;
       this.dialogDelete=true;      
    },

    clear(){
         this.id='0';
         this.categorie='';
         this.texte='';
         this.lien1='';
         this.lien2='';
         this.date_limite='';
         this.textetat=false; 
    }

    },
   
   mounted() {
    this.readAll();
    this.readAllpays();
    this.readCategorie();    
  },


}
</script>

<style lang="scss" scoped>
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>